import type { TypeAdvantagesGraphQL } from '@/lib/graphql/types';
import type { TypeAdvantagesProps } from '@/molecules/advantages/types';

export const advantagesTransformer = (
  advantages: TypeAdvantagesGraphQL,
): TypeAdvantagesProps => ({
  title: advantages.titulo ?? '',
  advantages: advantages.items.map((advantage) => ({
    ...(advantage.titulo ? { title: advantage.titulo } : {}),
    ...(advantage.descripcion ? { copy: advantage.descripcion } : {}),
    ...(advantage.icon
      ? {
          icon: {
            src: advantage.icon.sourceUrl,
            alt: advantage.icon.altText,
          },
        }
      : {}),
  })),
});
