import { useContext } from 'react';

import { Container } from '@/atoms';
import { Advantages } from '@/molecules';

import { InitPropsProvider } from '@/components/providers/initPropsProvider';
import { advantagesTransformer } from '@/lib/graphql/transformers';

const PorqueComprar = ({ id, className }: any) => {
  const propsProvider = useContext(InitPropsProvider);
  const porQueComprar = propsProvider.options.ajustesGenerales.porQueComprar;

  return (
    <Container
      id={id}
      wrapper
      padding="py-4 lg:px-4 md:py-8"
      className={`-mt-9 md:-mt-20 ${className}`}
    >
      <Advantages {...advantagesTransformer(porQueComprar)} />
    </Container>
  );
};

export default PorqueComprar;

PorqueComprar.displayName = 'FlexiblePorqueComprar';
